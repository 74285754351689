<template>
    <section class="hero">
      <template v-if='data.is_video'>
      <video 
        type="video/mp4" 
        class='hero__video'
        ref="video" 
        :poster="data.poster" 
        :src="data.background_video.url"
        :data-video="data.is_video" 
        autoplay 
        playsinline 
        loop 
        muted 
        v-show="windowWidth > 801">
       </video>
       <video  
         type="video/mp4"  
         class='hero__video' 
         ref="video" 
         autoplay 
         playsinline 
         loop 
         muted 
         :data-video="data.is_video" 
         :poster="data.poster" 
         :src="data.background_video_mobile.url" 
         v-show=" windowWidth < 499"
         >
       </video>
        <video  
         type="video/mp4"  
         class='hero__video' 
         ref="video" 
         autoplay 
         playsinline 
         loop 
         muted 
         :data-video="data.is_video" 
         :poster="data.poster" 
         :src="data.background_video_tablet.url" 
         v-show="windowWidth > 500 && windowWidth <  800"
         >
        </video>
      </template>
             
      <template v-else>
          <img :src="data.background_image.url" class='hero__video' :data-video="data.is_video" alt="">
      </template>
      <div class="hero__wrapper">
      <h1 class="hero__header" v-html="data.text"></h1>
    </div>
    </section>
</template>

<script>
export default{
  name: 'hero',
  data() {
    return {
      windowWidth: 0,
    };
  },
	mounted(){
		if(this.$refs.video){
			this.$refs.video.addEventListener("canplay",function(){
				document.dispatchEvent(new Event('hero-video'))
			});
		}
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  }
}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';
.hero{
    height: 68vh;
    min-height: 570px;
    width: 100%;
    position: relative;
    color: $black_brown;
    overflow: hidden;
    &__header{
        font-size: 8rem;
        text-align: center;
        font-family: 'Financier';
        color: #FFF;
        font-weight: 300;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        z-index: 1;
        max-width: 85%;
        @include tabletSize {
          font-size: 3.9rem;
          width: 90%;
        }
        @media (max-width: 1080px){
          top: 47%;

        }
    }
    &__video{
      min-width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
    &__wrapper{
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
    }
}
</style>
