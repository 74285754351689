import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)


export default new Vuex.Store({
    state: {
        content: null,
        loadingStatus: 'notLoading',
		first_name: null,
        last_name: null,
		phone_number: null,
        email_address: null,
        arrive_date: new Date(),
        departure_date: new Date(new Date().setDate(new Date().getDate() + 1)),
        adults: 1,
        kids: 0,
        infants: 0,
        bedrooms: 1,
		kinderbeds: 0,
        addons: [],
        comments: "",
        picked_villa: null,
        picked_villa_name: null,
        count: 6,
        recaptchaToken: null
    },
    mutations: {
        SET_LOADING_STATUS(state, status) {
            state.loadingStatus = status
        },
		SET_PHONE_NUMBER(state, content){
			state.phone_number = content
		},
        SET_CONTENT(state, content) {
            state.content = content
        },
        SET_LAST_NAME(state, content) {
            state.last_name = content
        },
        SET_FIRST_NAME(state, content) {
            state.first_name = content
        },
        SET_EMAIL_ADDRESS(state, content) {
            state.email_address = content
        },
        SET_ARRIVE_DATE(state, content) {
            state.arrive_date = content
        },
        SET_DEPARTURE_DATE(state, content) {
            state.departure_date = content
        },
        SET_ADULTS(state, content) {
            state.adults = content
        },
        SET_KIDS(state, content) {
            state.kids = content
        },
        SET_INFANTS(state, content) {
            state.infants = content
        },
        SET_BEDROOMS(state, content) {
            state.bedrooms = content
        },
        SET_KINDERBEDS(state, content) {
            state.kinderbeds = content
        },
        SET_ADDONS(state, content) {
            const index = state.addons.indexOf(content);
            if (index > -1) {
                state.addons.splice(index, 1);
            } else {
                state.addons.push(content)
            }
        },
        SET_COMMENTS(state, content) {
            state.comments = content
        },
        RECAPTCHA_TOKEN(state, content) {
            state.recaptchaToken = content
        },
        SET_PICKED_VILLA(state, item) {
            if (state.picked_villa == item.id) {
                state.picked_villa = false;
                state.picked_villa_name = false;
                return;
            }
            state.picked_villa = item.id;
            state.picked_villa_name = item.name;
        },
        countPosts(state, count) {
            state.count = count
        }
    },
    actions: {
        countPosts: ({commit}, count) => {
            commit(countPosts, count)
        },
        fetchContent(context) {
            context.commit('SET_LOADING_STATUS', 'loading')
            return axios.get(process.env.VUE_APP_API + 'wp-json/better-api/v1/content')
                .then((response) => {
                    context.commit('SET_CONTENT', Object.freeze(response.data))
                    context.commit('SET_LOADING_STATUS', 'notLoading')
                })
        },
        sendEnquiryForm(context) {
			const formatDate = (date) => {

				let day = ("0" + date.getDate()).slice(-2);
				let month = ("0" + (date.getMonth() + 1)).slice(-2);

				let year = date.getFullYear();
				if(date){
					return `${year}-${month}-${day}`
				} else return "";
			}
            let enquiryData = {...context.getters.getEnquiryData};
			enquiryData.arrive_date = formatDate(enquiryData.arrive_date);
			enquiryData.departure_date = formatDate(enquiryData.departure_date);
            return axios.post(process.env.VUE_APP_API + 'wp-json/better-api/v1/send-reservation', enquiryData).then(response => {
				// console.log(response);
                if (response.data == "ok") {
                    return {
                        error: false,
                        msg: "Message sent successfully."
                    };
                } else {
                    return {
                        error: true,
                        msg: "Something went wrong, our administrator got notified about the problem. Please contact info@atriumgroup.ch to book an enquiry."
                    }
                }
            })
        }

    },
    getters: {
        getPickedVilla: state => state.picked_villa,
        getEnquiryData: state => {
            return {
                last_name: state.last_name,
				first_name: state.first_name,
				phone_number: state.phone_number,
                email_address: state.email_address,
                arrive_date: state.arrive_date,
                departure_date: state.departure_date,
                adults: state.adults,
                kids: state.kids,
                infants: state.infants,
                bedrooms: state.bedrooms,
                kinderbeds: state.kinderbeds,
                addons: state.addons,
                picked_villa_name: state.picked_villa_name,
				picked_villa_id: state.picked_villa,
                comments: state.comments,
                recaptcha: state.recaptchaToken
            }
        },
        getFlexibleContent: (state) => (slug) => {
            if (state.content != null) {
                return state.content.pages.find(page => page.slug === slug).flexible_content
            }
        },
        getPageByPath: (state, getters) => (path) => {
            if (state.content != null) {
                // remove from path public path
                path = path.replace(getters.getPublicPath, '')
                // remove last slash (in case prerendering path looks like this "/subpage/" instead of "/subpage")
                if (path[path.length - 1] == '/') {
                    path = path.substr(0, path.length - 1);
                }
                // analyze path without first "/"
                let routes = path.substr(1).split('/')
                // get current locale
                let locale = getters.getLocale
                // if page has no parent
				if(routes.length > 1){
					if (routes.find(el => (el == "blog" || el == "team"))) {
						routes.shift();
					}
				}
                if (routes.length == 1) {
                    // if empty then set to default home
                    // find home slug of current locale
                    let home_slug = getters.getLanguages.find(lang => lang.lang === locale).home_slug

                    let route = routes[0] ? routes[0] : home_slug
                    return getters.getPageIdBySlugAndParentAndLang(route, 0, locale)
                } else if (routes.length == 2) {
                    // get parents object
                    let parent = getters.getPageIdBySlugAndParentAndLang(routes[0], 0, locale)
                    return getters.getPageIdBySlugAndParentAndLang(routes[1], parent.id, locale)
                }
                // more than 2 nesting needs implement
            }
        },
        getPosts: (state, getters) => {
            // console.log(state.count);
            if (state.content != null) {
                // return state.content.posts
                let locale = getters.getLocale
                let posts = state.content.posts.filter(post => post.lang === locale)
                let result
                // if there is one item object then convert it to array
                if (posts.length === undefined) {
                    result = new Array(posts)
                } else {
                    result = posts
                }
                // return result
                return result.slice(0, state.count)
            }
        },
		getPostsByTag: (state,getters) => tagSlug => {
			let posts = getters.getPosts;
			posts.filter(el => el.tags.find(tag => tag.slug === tagSlug));
            // console.log(posts);
            return posts.slice(0, state.count);
		},
        getBlock: (state) => (id) => {
            if (state.content != null) {
                return state.content.blocks[id].flexible_content
            }
        },
        /* Languages */
        getLanguages: (state) => {
            if (state.content != null) {
                return state.content.languages
            }
        },
        getLocale: (state, getters) => {
            if (state.content != null) {
                // all available languages
                const langs = getters.getOptions.languages

                // set default language
                const defaultLocale = getters.getOptions.default_language;

                // get language from url
                const urlLocale = window.location.pathname.replace(process.env.BASE_URL, '/').replace(/^\/([^\/]+).*/i, '$1');

                // if url has no known language then set default language
                const locale = langs.includes(urlLocale) ? urlLocale : defaultLocale;

                return locale
            }
        },
        /* Menus */
        getMainMenu: (state, getters) => {
            if (state.content != null) {
                const locale = getters.getLocale
                return state.content.menus.main_menu[locale]
            }
        },
        /* Options */
        getOptions: (state) => {
            if (state.content != null) {
                return state.content.options
            }
        },
        /* Footer */
        getOptionsFooter: (state, getters) => {
            if (state.content != null) {
                // const locale = getters.getLocale
                return state.content.options.footer
            }
        },
         getPartners: (state, getters) => {
            if (state.content != null) {
                // const locale = getters.getLocale
                return state.content.options.partners
            }
        },
        getPatnersPage: (state, getters) => {
            if (state.content != null) {
                // const locale = getters.getLocale
                return state.content.partners
            }
        },
        getNewsletter: (state, getters) => {
            if (state.content != null) {
                // const locale = getters.getLocale
                return state.content.options.newsletter
            }
        },
        getOptionsFavicon: (state) => {
            if (state.content != null) {
                return state.content.options.favicon
            }
        },
        getSeoTitleTemplate: (state) => {
            if (state.content != null) {
                return state.content.options.seo_title_template
            }
        },
        getPublicPath: (state) => {
            return (process.env.VUE_APP_PUBLIC_PATH === undefined || process.env.VUE_APP_PUBLIC_PATH === null) ? '' : process.env.VUE_APP_PUBLIC_PATH
        },
        /* Specific getters */
        getPageIdBySlugAndParentAndLang: (state) => (slug, parent, lang) => {
            if (state.content != null) {
                let result
                result = state.content.pages.find(page => page.slug === slug && page.parent_id === parent && page.lang === lang)
                if (result === undefined) {
                    result = state.content.posts.find(post => post.slug === slug && post.lang === lang)
                }
                if (result === undefined) {
                    result = state.content.team.find(post => post.slug === slug && post.lang === lang)
                }
                return result
            }
        },
        getCountOfVillas: state => state.content.planyo.count,
        getAllVillas: state => state.content.planyo.villas,
        getVillaById: state => id => state.content.planyo.villas.find(el => el.id == id),
		getArrivalDate: state => state.arrive_date,
		getDepartureDate: state => state.departure_date,
		getAdults: state => state.adults,
		getKids: state => state.kids,
		getInfants: state => state.infants,
		getBedrooms: state => state.bedrooms,
		getKinderbed: state => state.kinderbeds,
		getTeamMembers: state => state.content.team,
		getSocials: state => state.content.options.socials
    }
})
