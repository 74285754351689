<template>
    <section class="blog">
        <div class="blog__background">
            <img :data-src="data.background" class="lozad" alt="background image">
        </div>
        <div class="blog__container">
            <!-- <div class="blog__choose">
                <div class="blog__right">
                    <span>Categories</span>
                    <div class="vue-select" @click="toggleDropdown">
                        <span class="selected-option">
                            {{ placeholder }}
                            <svg width="24" height="24" viewBox="0 0 24 24">
                                <path d="M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z"></path>
                            </svg>
                        </span>
                        <transition name="slide">
                            <div
                                class="dropdown-options-container"
                                v-show="showDropdown"
                            >
                                <div
                                    class="dropdown-options"
                                    v-for="option in options"
                                    :class="{'selected': option === selected}"
                                >
                                    <div class="dropdown-options--cell" @click="selectOption(option)">
                                        <span class="option-text">{{ option }}</span>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div> -->
            <div class="blog__heading">
                <router-link class="blog_page_link" to="/blog"><h2>{{data.title}}</h2></router-link>
            </div>
            <div class="blog__box">
                <div class="blog__gray-bgc"></div>
                <div class="blog__post" v-for="(post, key) in posts" :key="key">
                    <div class="blog__img">
						<router-link :to="post.link">
							<img :data-src="post.thumbnail" class="lozad" :alt="post.title + ' - thumbnail'">
						</router-link>
                    </div>
                    <router-link :to="post.link" class="blog__title"><h3 v-html="post.title"></h3></router-link>
                    <span class="blog__info">{{post.date}} BY {{post.author ? post.author : "Atrium team"}}</span>
                    <post-tags class="tags" :tags="post.tags"></post-tags>

                </div>
            </div>
            <!-- <div class="blog__btn-box">
                <a @click="togglePost" class="blog__btn">LOAD MORE</a>
            </div> -->
        </div>
    </section>
</template>

<script>
import PostTags from "@/components/partials/PostTags.vue";
export default {
	components: {
		PostTags
	},
    data() {
        return {
            count: 6
        }
    },
    methods: {
        togglePost() {
            this.count = this.count + 6
            return this.$store.commit("countPosts", this.count)
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        selectOption(option) {
            this.selected = option;
            this.placeholder = option;
            this.$emit('interface', this.selected)
        },
    },
    computed: {
        posts() {
			if(this.$route.query.tag){
				return this.$store.getters.getPostsByTag(this.$route.query.tag)
			} else {
				return this.$store.getters.getPosts
			}
        },
        postsPage() {
            return this.$store.getters.getOptionsPostsPage
        },
        getAllTags(){
            let allValues = this.posts.reduce(function(prev, current){

            })
        }
    },
    created(){
        console.table(this.posts);
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/buttons.scss';
@import '@/styles/framework/mixins.scss';
.blog_page_link {
	color: $brown;
	text-decoration: none;
	will-change: color;
	transition: color 200ms;
	&:hover {
		color: lighten($brown,15%);
	}
}
.blog {
    padding: 160px 0 160px 80px;
    position: relative;
    @include tabletSize {
        padding: 0;
    }
	&__title {
		text-decoration: none;
			h3{
				will-change: color;
				transition: color 200ms;
				&:hover {
					color: $brown;
				}
			}
	}
    &__choose{
        display: flex;
        justify-content: flex-end;
        padding: 0 160px;
        @include breakpoint-max('large') {
            padding: 0 120px;
        }
        @include tabletSize {
            padding: 54px 25px 66px 25px;
        }
    }
	&__info {
		text-transform: uppercase;
	}
    &__background {
        position: absolute;
        width: 100%;
        height: 50%;
        top: -160px;
        left: 0;
        z-index: -1;
        img {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        @include tabletSize {
            display: none;
        }
    }
    &__right{
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        span{
            font-size: 1.4rem;
            color: $black_brown;
            text-transform: uppercase;
            align-self: flex-end;
        }
    }
    &__container {
        background-color: $gray;
        @include mobile {
            background-color: $white;
        }
    }
    &__heading {
        padding: 83px 0 22px 0;
        text-align: center;
        font-size: $small_heading;
        color: $brown;
        h2 {
            font-weight: 400;
        }
        @include tabletSize {
            h2 {
                display: none;
            }
        }
    }
    &__gray-bgc {
        display: none;
        @include tabletSize {
            display: block;
            position: absolute;
            top: -30px;
            left: 0;
            width: 100%;
            height: 15%;
            background-color: $gray;
        }
    }
    &__box {
        padding: 0 160px 60px 160px;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 70px 40px;
        @include breakpoint-max('large') {
            padding: 0 120px;
        }
        @include breakpoint-max('big') {
            grid-template-columns: repeat(2, 1fr);
        }
        @include tabletSize {
            position: relative;
            grid-template-columns: 1fr;
            padding: 54px 25px 66px 25px;
        }
    }
    &__post {
        img {
            max-height: 230px;
            min-height: 230px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include tabletSize {
                object-fit: contain;
            }
            @include xs-mobile {
                object-fit: cover;
            }
        }
        span {
            font-size: $mobile_txt;
            color: $dark_gray;
        }
        h3 {
            font-size: $big_txt;
            font-family: Financier;
            color: $black_brown;
            font-weight: 400;
        }
        @include tabletSize {
            text-align: center;
            z-index: 10;
        }
        @include mobile {
            h3 {
                font-size: $mobile_heading;
            }
        }
    }
    &__btn-box {
        padding: 93px 0 80px 0;
        text-align: center;
        @include tabletSize {
            display: none;
        }
    }
    &__btn {
        @include button;
        z-index: 1;
    }
}

.tags {
    a {
        color: $dark_gray;
    }
}

$vue-navy: #2c3e50;
$vue-navy-light: #3a5169;
$vue-teal: #42b883;
$vue-teal-light: #42b983;
$light-gray: #f8f8f8;

@mixin active-state() {
    background-color: #f4fbf8;
    border: none;
}

@mixin ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

h1 {
    font-size: 3.6em;
    font-weight: 100;
    text-align: center;
    margin-bottom: 0;
    color: $vue-teal;
}

p {
    color: $vue-navy-light;
    text-align: center;
    margin-top: 10px;
}

.vue-select {
    margin-right: 0;
    cursor: pointer;
    user-select: none;
    border: none;
    transition: all 200ms linear;

    .selected-option {
        @include ellipsis();
        display: inline-block;
        padding: 15px 50px 15px 15px;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        transition: all 200ms linear;

        &:hover {
            color: rgba(0,0,0,.45);
        }

        svg {
            fill: $vue-teal;
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            transition: fill 300ms linear;
            &:hover {
                fill: darken($vue-teal, 15%);
            }
        }
    }
}

.dropdown-options-container {
    overflow-y: scroll;
    position: absolute;
    background-color: #FFF;
    z-index: 800;
    max-width: 300px;
    width: 100%;
}

.dropdown-options--cell {
    padding: 15px;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        @include active-state();
    }
}
.dropdown-options.selected {
    .dropdown-options--cell {
        @include active-state();
    }
}

.slide-enter-active, .slide-leave-active  {
    transition: height 150ms ease;
}

.slide-enter, .slide-leave-to {
    height: 0px;
}

</style>
