<template>
    <section class="info" :class="[{'full-width margin-bottom full-background' : data.is_main, 'home-page-view': data.is_home_page},data.custom_class]" >
        <p class="info__smaller-text" v-html="data.smaller_text"></p>
        <p class="info__bigger-text" v-html="data.bigger_text"></p>
        <img v-if="data.is_main" class='info__background-img lozad' :data-src="data.background_img.url" alt="background image">
        <div  v-if="data.is_main" class="info__background"></div>
    </section>
</template>

<script>
export default{
    name: 'info'
}
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';
.info{
    max-width: 1040px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding: 80px 70px;
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
       @include tabletSize {
             padding: 40px 10px;
        }
    &__smaller-text{
        color: $brown;
        text-transform: uppercase;
        margin-bottom: 20px;
        font-size: 2.2rem;
        z-index: 1;
        position: relative;
        @include tabletSize {
          font-size: 1.6rem;
          padding: 0 8% 4%;

        }
    }
    &__bigger-text{
        font-size: 4.4rem;
        color: $black_brown;
        font-family: 'Financier';
        z-index: 1;
        position: relative;
        padding: 0;
        @include tabletSize {
          font-size: 2.4rem;
          padding: 0 5%;
        }
        @media (max-width: 1023px){
          padding: 0 6%;
        }
    }
    &__background-img{
      position: absolute;
      left: 1%;
      bottom: -40px;
      z-index: -2;
      max-width: 438px;
      height: 110%;
      @include tabletSize{
        left: auto;
        right:0;
        max-width:80%;

      }
    }
    &__background{
      position:absolute;
      height: 100%;
      left: 3%;
      bottom:0;
      right: 0;
      background-color: $beige;
      @include tabletSize{
        left: 0;
      }
    }
}

.full-background{
      top: -38px;
      .info__background-img{
        left: 0;
        top:0;
  }
}
.full-width{
     max-width: 100%;
    .info__smaller-text{
      max-width: 1240px;
      margin: 0 auto;
      width: 100%;
    }
    .info__bigger-text{
       max-width: 1240px;
       margin: 0 auto;
       width: 100%;
    }
}
.margin-bottom{
  margin-bottom: 150px;
  @media (max-width: 700px){
    margin-bottom: 0px;
  }
}

.home-page-view{
    margin-top: 115px;
  .info__background{
     height: 150%;
     top: -50%;
     z-index: -1;
   }
    .info__background-img{
       left:1%;
       top:auto;
       height: auto;
       max-height: 310px;
 }
}
.reverse{
  .info__background-img{
    left: auto;
    right: 0;
  }
  .info__background{
    left: 0;
    right: 3%;
  }
}
</style>
