<template>
    <section class="partners" v-if="partners">
        <router-link class="partners__box" to='/partners' tag='span'>
        <div class="partners__txt">

            <span @click='goTo'>Partners</span>

        </div>
        <InfiniteSlideBar class="partners__slider" :barStyle="{ background: '#2E2E33', height: '100%',}">
            <div class="partners__case">
                <router-link to='/partners' class="partners__item" v-for="(partner, key) in partners" :key="key">
                    <img :src="partner.partner.url" alt="partner logo">
                </router-link>
            </div>
        </InfiniteSlideBar>
        </router-link>
    </section>
</template>

<script>
import InfiniteSlideBar from 'vue-infinite-slide-bar'

    export default {
        computed: {
		partners(){
	        return this.$store.getters.getPartners
        },
      },
    methods:{
        goTo(){
            this.$router.push('/partners')
        }
    },
      components: {
        InfiniteSlideBar
      }
    }
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';

.partners {

    &__box {
        display: flex;
        min-height: 80px;
        border-bottom: 1px solid $text_color;
    }
    &__txt {
        width: 23%;
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: $small_heading;
        background-color: $gold_sand;
        color: $white;
        @include tabletSize {
          width: 50%;

        }
    }
    &__slider {
        width: 77%;
        min-height: 80px;
    }
    &__case {
        display: flex;
        height: 100%;
        align-items: center;
    }
    &__item {
        height: 28px;
        max-width: 230px;
        margin: 0 60px;
        flex: 1;
        img {
            height: 100%;
            width: auto;
            object-fit: contain;
        }
    }
}


</style>
