<template>
    <section class="partners">
         <div class="partners__background">
            <img src="@/assets/img/backImgPartenrs.png" class="lozad" alt="background image">
        </div>
        <div class="partners__container"> 
            <div class="partners__heading">
                <h2 class="partners_page_link">Partners</h2>
            </div>
         <div class="partners__box">
                <div class="partners__gray-bgc"></div>
                <div class="partners__post" v-for="(post, key) in data.partners" :key="key">
                        <a :href='post.partner.link.url' class="partners__img-wrapper" target="_blank">
						<img :src="post.partner.image.url" class="lozad partners__img">
                        </a>

                        <!-- <h3 class="partners__title"><a :href='post.partner.link.url'>{{post.partner.title}}</a></h3> -->
                        <div class="partners__info" >
                                {{post.partner.content}}
                                              <a :href='post.partner.link.url' class="partners__link" target="_blank"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.3 1L7.5 9.8M16.3 1H9.8M16.3 1V7.5M14.1 9.8V16.3H1V3.3H7.5" stroke="#A66E5D" stroke-miterlimit="10"/>
                                            </svg>
                                            GO TO WEBSITE</a>
                        </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        getPatners(){
        return this.$store.getters.getPatnersPage
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/buttons.scss';
@import '@/styles/framework/mixins.scss';
.partners_page_link {
	color: $brown;
	text-decoration: none;
	will-change: color;
    font-weight:normal;
	transition: color 200ms;
	&:hover {
		color: lighten($brown,15%);
	}
}
.partners {
    padding: 160px 0 80px 80px;
    position: relative;
    @include tabletSize {
        padding: 0;
    }
	&__title {
        margin-bottom: 10px;
        margin-top: 10px;
        a{
        font-family: "Financier";
        font-size: 3.4rem;
        line-height: 3.9rem;
        color: $black_brown;
        transition: color .6s;
        font-weight: 500;
        text-decoration: none;
        &:hover{
            color: $brown;
        }
        }
	}
    &__choose{
        display: flex;
        justify-content: flex-end;
        padding: 0 160px;
        @include breakpoint-max('large') {
            padding: 0 120px;
        }
        @include tabletSize {
            padding: 54px 25px 66px 25px;
        }
    }
	&__info {
        min-height: 200px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        justify-content: space-between;
        p{
            font-family: Poppins;
            color:#828282;
            font-size: 1.7rem;
            line-height: 2.7rem;
        }
    }
    &__link{
        color: #A66E5D;
        font-size: 1.4rem;
        text-decoration: none;
        margin-top: 20px;
        display: flex;
        align-items: center;
        transition: color .6s;
        svg{
            margin-right: 5px;
            path{
            transition: all .6s;

            }
        }
        &:hover {
            color: $gold_sand;
        
        }
        &:hover  svg path{
            stroke:  $gold_sand;
            }
    }
    &__background {
        position: absolute;
        width: 100%;
        height: 50%;
        top: -160px;
        left: 0;
        z-index: -1;
        img {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        @include tabletSize {
            display: none;
        }
    }
    &__right{
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        span{
            font-size: 1.4rem;
            color: $black_brown;
            text-transform: uppercase;
            align-self: flex-end;
        }
    }
    &__container {
        background-color: $gray;
        padding-bottom: 10%;
        @include mobile {
            background-color: $white;
        }
    }
    &__heading {
        padding: 83px 0 22px 0;
        text-align: center;
        font-size: $small_heading;
        color: $brown;
        h2 {
            font-weight: 400;
        }
        @include tabletSize {
            h2 {
                display: none;
            }
        }
    }
    &__gray-bgc {
        display: none;

    }
    &__box {
        padding: 0 160px 60px 160px;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 70px 40px;
        border: none;
        @include breakpoint-max('large') {
            padding: 0 120px;
        }
        @include breakpoint-max('big') {
            grid-template-columns: repeat(2, 1fr);
        }
        @include tabletSize {
            position: relative;
            grid-template-columns: 1fr;
            padding: 54px 15px 66px 15px
        }
    }
    &__img-wrapper{
        min-height: 230px;
        padding: 0 20px;
        object-fit: cover;
        width:100%;
        &:hover + .partners__title {
            color: $brown;
        }
    }
    &__img{
            max-height: 230px;
            object-fit: contain;
            position: relative;
            z-index: 1;
            @include tabletSize {
                object-fit: contain;
            }
            @include xs-mobile {
                object-fit: cover;
                width:100%;
            }
    }
    &__btn-box {
        padding: 93px 0 80px 0;
        text-align: center;
        @include tabletSize {
            display: none;
        }
    }
    &__btn {
        @include button;
        z-index: 1;
    }
    &__img-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
           @include mobile {
             background: #eee;
        }
    }
}

.tags {
    a {
        color: $dark_gray;
    }
}

$vue-navy: #2c3e50;
$vue-navy-light: #3a5169;
$vue-teal: #42b883;
$vue-teal-light: #42b983;
$light-gray: #f8f8f8;

@mixin active-state() {
    background-color: #f4fbf8;
    border: none;
}

@mixin ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

h1 {
    font-size: 3.6em;
    font-weight: 100;
    text-align: center;
    margin-bottom: 0;
    color: $vue-teal;
}

p {
    color: $vue-navy-light;
    text-align: center;
    margin-top: 10px;
}

.vue-select {
    margin-right: 0;
    cursor: pointer;
    user-select: none;
    border: none;
    transition: all 200ms linear;

    .selected-option {
        @include ellipsis();
        display: inline-block;
        padding: 15px 50px 15px 15px;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        transition: all 200ms linear;

        &:hover {
            color: rgba(0,0,0,.45);
        }

        svg {
            fill: $vue-teal;
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            transition: fill 300ms linear;
            &:hover {
                fill: darken($vue-teal, 15%);
            }
        }
    }
}

.dropdown-options-container {
    overflow-y: scroll;
    position: absolute;
    background-color: #FFF;
    z-index: 800;
    max-width: 300px;
    width: 100%;
}

.dropdown-options--cell {
    padding: 15px;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        @include active-state();
    }
}
.dropdown-options.selected {
    .dropdown-options--cell {
        @include active-state();
    }
}

.slide-enter-active, .slide-leave-active  {
    transition: height 150ms ease;
}

.slide-enter, .slide-leave-to {
    height: 0px;
}

</style>
