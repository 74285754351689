import VideoObserver from "./scripts/videoObserver";
import SyncEnquiry from "./scripts/syncEnquiry";
import Scrollbar from 'smooth-scrollbar';


// If you want to install any additional scripts just import them and add to the utils object, they will be available in components under this.$bamUtils
const utils = {
	videoObserver: VideoObserver,
	syncEnquiry: SyncEnquiry,
	scrollbar: Scrollbar,
	scroll: ''
}

const install = Vue => {
	Vue.bamUtils = utils,
	Vue.prototype.$bamUtils = utils
};

export default {install}
