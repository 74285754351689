<template id="">
  <div class="img_text_img" :class='data.custom_class'>
  <div class="img_text_img__bottom-conatainer">
    <div class="img_text_img__img-wrapper">
	  <lazy-image class='img_text_img__img-small' v-if="data.image_small.sizes" :bamSmallImage="data.image_small.sizes.size50x33" :bamAlt="data.image_small.alt" :bamFullImage="data.image_small.url"></lazy-image>
	  <lazy-image class='img_text_img__img-background' v-if="data.background_image.sizes" :bamSmallImage="data.background_image.sizes.size50x33" :bamAlt="data.background_image.alt" :bamFullImage="data.background_image.url"></lazy-image>
    </div>
    <div class="img_text_img__text-container  img_text_img__text-container--bottom" >
      <h2  v-if="data.title">{{data.title}}</h2>
      <div class="img_text_img__content" v-html='data.text'>
      </div>
      <router-link :to="data.button.url" v-html="data.button.title" class="img_text_img__button" v-if="data.button"></router-link>
    </div>
    <template v-if='data.video_as_source'>
		<lazy-video class="img_text_img__img-large" :bamSrc="data.video.url" :bamPoster="data.poster.url"></lazy-video>
    </template>
    <template v-else>
	   <lazy-image class='img_text_img__img-large' :bamSmallImage="data.image_big.sizes.size50x33" :bamAlt="data.image_big.alt" :bamFullImage="data.image_big.url"></lazy-image>
    </template>
  </div>
  <div class="img_text_img__background">
  </div>
</div>
</div>

</template>

<script>
export default {
    mounted() {
		// let video = this.$refs.video;
        // if (video) {
		// 	this.$bamUtils.videoObserver(video)
        // }
    }
}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/buttons.scss';
@import '@/styles/framework/mixins.scss';

.img_text_img{
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
  @include tabletSize{
    padding: 30px 0 80px;
    overflow : hidden;
  }

  &__background{
    background-color: $beige;
    width:95%;
    height: 140%;
    z-index: -2;
    position: absolute;
    left: 0;
    bottom: 120px;
    @media (max-width: 970px){
      width:95%;
      height: 135%;
      bottom: 8%;
    }
  }
  &__text-container{
    max-width:559px;
    width:50%;
    margin-left: 5%;
    margin-bottom: $button_margin;
    color: $text_color;
    h2 {
        font-family: 'Financier';
        font-weight: 500;
        font-size: $big_heading;
        color: $brown;
        padding-bottom: 25px;
        @include tabletSize {
            font-size: $mobile_big;
        }
    }
    @media (max-width: 970px){
      width:100%;
      padding: 0 8%;
      margin-left: 0;
    }
    &--bottom{
      max-width:550px;
      margin:20px 5% 0;
      width:30%;
      font-size:$small_txt;
      @media (max-width: 970px){
        width: 100%;
        margin-top: 30px;
        font-size:$mobile_txt;
      }
    }
  }
  &__content{
    color: $text_color;
    font-size:$small_txt;
    margin-bottom: $button_margin;
    @media (max-width: 970px){
      margin-bottom: 10%;
      max-width:550px;
      width: 100%;
      font-size:$mobile_txt;
    }
  }
  &__bottom-conatainer{
    align-items:center;
    display:flex;
    justify-content: space-between;
    position: relative;
    @media (max-width: 970px){
      flex-direction: column-reverse;
    }
  }
  &__img-small{
    align-self:flex-end;
    object-fit:cover;
    object-position:center;
    width: 100%;
    height: 100%;
	visibility: hidden;
    @media (max-width: 970px){
      display:none;
    }

  }
  &__img-large{
    align-self:center;
    max-width:700px;
    object-fit:cover;
    object-position:center;
    width: 45%;
    max-width: 700px;
    max-height: 480px;
    @media (max-width: 970px){
      max-width: auto;
      width:80%;
      align-self:flex-start;
    }
  }
  &__button{
    @include button;
    z-index: 2;
  }
  &__img-background{
    z-index: -2;
    position: absolute;
    max-width: 520px;
    max-height: 560px;
    object-fit: cover;
    width: 20vw;
    left: 100%;
    bottom: -40px;
    @media (max-width: 970px){
      left:0%;
      bottom: 10px;
      width: 100vw;
      height: 50vh;
      max-width: initial;
      max-height: initial;
    }
    @media (max-width: 900px){
      height: 70vh;
    }
    @media (max-width: 600px){
      height: 50vh;
    }
  }
  &__img-wrapper{
    width: 25%;
    max-width: 400px;
    max-height: 362px;
    bottom: -85px;
    position: relative;
    @media (max-width: 970px){
      width: 75%;

    }
  }
}
.reverse{
  padding: 0 40px 0 0;
  padding-bottom: 120px;
  @include tabletSize{
    padding: 30px 0 80px;

  }
  .img_text_img__bottom-conatainer{
    flex-direction: row-reverse;
  }
  .img_text_img__img-background{
    right: 60%;
    left: auto;
    width: 30vw;
    bottom: -30px;
    @include tabletSize{
      left: 0;
      bottom: -60px;
      right: auto;
      width: 50vw;

    }
  }
  .img_text_img__img-wrapper{
    @include tabletSize{
      position: static;
    }
  }
  .img_text_img__bottom-conatainer {
    @include tabletSize{
      flex-direction: column-reverse;
    }
  }
  .img_text_img__img-large{
    @include tabletSize{
      width: 100%;
      max-width: 100%;
    }
  }
}
.no-padding-left{
  padding: 0;
}
.big-background{
  .img_text_img__background{
      left: 20px;
      right: 40px;
      bottom: 120px;
      height: 200%;
      width: auto;
      @include tabletSize{
        width: 100%;
        left: 20px;
        right: 0px;
        height: 131%;
        bottom: 50px;
      }
  }
}
.no-background{
  .img_text_img__content {
    color: $text_color;
  }
  .img_text_img__background{
    display: none;
  }
  .img_text_img__img-background{
      display: none ;
    }
}
.additional-padding-bottom{
  padding:0 0 10% ;
}
.additional-text{
  padding-bottom: 180px;
  .img_text_img__img-wrapper{
    bottom: -180px;
  }
  @include tabletSize{
    padding: 30px 0 80px;

  }
}
.black-title{
  h2{
    color: $black_brown;
  }
}
.reverse-background{

  .img_text_img__bottom-conatainer{
    flex-direction: row-reverse;
  }
  .img_text_img__img-background{
    right: 60%;
    left: auto;
    width: 30vw;
    bottom: -30px;
    @include tabletSize{
      left: 0;
      bottom: -40px;
      right: auto;
      width: 50vw;

    }
  }
  .img_text_img__img-wrapper{
    @include tabletSize{
      position: static;
    }
  }
  .img_text_img__bottom-conatainer {
    @include tabletSize{
      flex-direction: column-reverse;
    }
  }
  .img_text_img__img-large{
    @include tabletSize{
      width: 100%;
      max-width: 100%;
    }
  }
  .img_text_img__background{
    background-color: #f4f2ee;
    width: 95%;
    height: 150%;
    z-index: -2;
    position: absolute;
    left: auto;
    right: 0;
    bottom: 130px;
      @include tabletSize{
          bottom: 100px;

    }
  }
}
</style>
