<template>
    <section class="singlepost">
        <div class="singlepost__background">
            <img src="@/assets/img/marble.png" alt="">
        </div>
        <div class="singlepost__container">
			<div class="singlepost__gray-bgc"></div>
            <div class="singlepost__heading">
                <div class="singlepost__heading-case">
					<back-button class="singlepost__heading-btn"></back-button>
				</div>
                <router-link to="/blog" class="blog_page_link"><h2>BLOG</h2></router-link>
                <div class="singlepost__heading-next">
					<a  @click.prevent="nextPost()" class="singlepost__heading-btn">Next</a>
                    <img src="@/assets/img/ARROW.png" alt="Go Next">
				</div>
            </div>
            <div class="singlepost__box">
				<div class="singlepost__img">
					<img :src="post.image" :alt="post.title">
				</div>
				<div class="singlepost__title">
					<h3 v-html="post.title"></h3>
                    <post-tags :tags="post.tags"></post-tags>
				</div>
                <div class="singlepost__content">
                    <p v-html="post.content"></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import PostTags from "@/components/partials/PostTags.vue";
import BackButton from "@/components/partials/BackButton.vue";
	export default {
		components: {
			PostTags,
			BackButton
		},
		computed: {
			post() {
				return this.$store.getters.getPageByPath(this.$route.path)
			},
            posts() {
    			return this.$store.getters.getPosts
    		},
    		lang() {
    			return this.$store.getters.getLocale;
    		},
    		postPosition() {
                console.log(this.post)
    			const allPosts = this.posts
    			let pos = allPosts.map(function(e) {
    					return e.id
    				})
    				.indexOf(this.page.id)
    			return pos
    		},
    		nextPost() {
                console.log(this.postPosition)
    			// if (this.postPosition + 1 > this.posts.length - 1) {
    			// 	return this.posts[0].slug
    			// } else {
    			// 	return this.posts[this.postPosition + 1].slug
    			// }
    		},
    		prevPost() {
    			if (this.postPosition - 1 < 0) {
    				return this.posts[this.posts.length - 1].slug
    			} else {
    				return this.posts[this.postPosition - 1].slug
    			}
    		}
		}
	}
</script>

<style lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/buttons.scss';
@import '@/styles/framework/mixins.scss';

.blog_page_link {
	color: $brown;
	text-decoration: none;
	will-change: color;
	transition: color 200ms;
	&:hover {
		color: lighten($brown,15%);
	}
}
.singlepost {
    padding: 160px 0 160px 80px;
    position: relative;
    @include tabletSize {
        padding: 0;
    }
    &__background {
        position: absolute;
        width: 100%;
        height: 20vh;
        top: 0;
        left: 0;
        z-index: -1;
        img {
            height: auto;
            width: 100%;
            object-fit: cover;
        }
        @include tabletSize {
            display: none;
        }
    }
    &__container {
		position: relative;
    }
    &__heading {
        padding: 83px 0 25px 0;
        display: flex;
        justify-content: space-around;
        font-size: $small_heading;
        color: $brown;
		h2 {
			font-weight: 400;
            font-size: $small_heading;
		}
        @include tabletSize {
            h2 {
                display: none;
            }
        }
    }
    &__heading-case {
		display: flex;
		align-items: center;
        justify-content: center;
        a {
            font-size: $mobile_txt;
        }
        img {
            margin-bottom: 2px;
        }
	}
    &__heading-next {
        display: flex;
		align-items: center;
        justify-content: center;
        a {
            font-size: 17px;
            text-transform: uppercase;
            color: $black_brown;
        }
        img {
            margin-left: 1rem;
            transform: rotate(180deg);
        }
        @include tabletSize {
            display: none;
        }
    }
	&__heading-btn {
        font-size: 17px !important;
        color: $black_brown !important;
        @include tabletSize {
			display: none;
		}
	}
    &__gray-bgc {
    	position: absolute;
		top: 0;
		left: 0;
		min-height: 55vh;
		z-index: -1;
		width: 100%;
		background-color: $gray;
        @include breakpoint-max('big') {
			min-height: 45vh;
		}
		@include tabletSize {
			display: none;
		}
    }
    &__box {
    	position: relative;
        padding: 0 160px;
        height: 100%;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
		@include breakpoint-max('big') {
			padding: 0 120px;
		}
		@include tabletSize {
			padding: 0;
		}
    }
	&__img {
        display: flex;
        justify-content: center;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
			object-position: center;
		}
	}
	&__title {
		text-align: center;
		padding: 0 30px;
		margin: 30px 0 60px 0;
		span {
			color: $dark_gray;
			font-size: $mobile_txt;
		}
		h3 {
			color: $black_brown;
			font-size: $big_heading;
			font-family: Financier;
			font-weight: 400;
            @include tabletSize {
                font-size: $mobile_heading;
                margin-bottom: 10px;
            }
		}
        @include tabletSize {
            margin: 60px 0 38px 0;
        }
	}
	&__content {
        margin-bottom: 50px;
		p {
			color: $text_color;
			font-size: $small_txt;
            @include tabletSize {
                padding: 0 23px;
                font-size: $mobile_txt;
            }
		}
	}
}

.img-and-description {
    margin: 80px 0;
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    p {
        font-size: $mobile_txt;
    }
}

.full-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.big-txt {
    margin: 60px 0;
    padding: 0 20px;
    font-size: $big-txt !important;
    font-weight: 500 !important;
    text-align: center;
    color: $text_color;
}

</style>
