<template>
    <header class="menu">
      <button class="menu__button" @click="active = !active" :class="{'no-hover active': active}">
          <div class="menu__lines" :class="{'menu__active': active}">
              <div class="menu__line menu__line--one"></div>
              <div class="menu__line menu__line--two"></div>
          </div>
          <span class='menu-button'>Menu</span>
      </button>
      <div class="menu__sidebar" :class="{'active': active}">
        <div class="menu__lang-buttons-wrapper">
            <!-- @click='showOptions = !showOptions' -->
            <button class="menu__lang-buttons-current">
                {{ locale }}
            </button>
            <div class="menu__lang-buttons-container" :class="{'show': showOptions}">

                <a
                    v-for="(lang, index) in languages"
                    :href="'/' + lang.lang"
                    :key="index"
                    :class="{'current': lang.lang == locale}"
                >{{ lang.lang }}</a>

            </div>
        </div>
        <div class="menu__social-wrapper">
            <a :href="socialLinks.facebook" target="_blank"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.28669 0C1.46601 0 0 1.46601 0 3.28669L0 14.7133C0 16.534 1.46601 18 3.28669 18L9.47983 18L9.47983 10.9631L7.61908 10.9631L7.61908 8.42962L9.47983 8.42962L9.47983 6.26512C9.47983 4.56457 10.5792 3.00319 13.1119 3.00319C14.1373 3.00319 14.8956 3.10163 14.8956 3.10163L14.8359 5.46751C14.8359 5.46751 14.0626 5.4602 13.2187 5.4602C12.3054 5.4602 12.159 5.881 12.159 6.57958L12.159 8.42964L14.9085 8.42964L14.7887 10.9631L12.159 10.9631L12.159 18L14.7133 18C16.534 18 18 16.534 18 14.7133L18 3.2867C18 1.46602 16.534 1.8e-05 14.7133 1.8e-05L3.28667 1.8e-05L3.28669 0Z" fill="#66625F"/>
            </svg>
            </a>
            <a :href="socialLinks.linkedin" target="_blank"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.52733 0C1.58757 0 0 1.58754 0 3.52733V14.4732C0 16.413 1.58754 18 3.52733 18H14.4732C16.413 18 18 16.413 18 14.4732V3.52733C18 1.58757 16.413 0 14.4732 0H3.52733ZM4.4145 2.97036C5.34457 2.97036 5.91746 3.58094 5.93514 4.38353C5.93514 5.1684 5.34454 5.79614 4.39651 5.79614H4.37906C3.4667 5.79614 2.87699 5.16844 2.87699 4.38353C2.87699 3.58095 3.48456 2.97036 4.4145 2.97036ZM12.4294 6.72158C14.2181 6.72158 15.559 7.89069 15.559 10.403V15.0932H12.8407V10.7175C12.8407 9.61793 12.4473 8.86776 11.4635 8.86776C10.7125 8.86776 10.2649 9.37338 10.0683 9.86183C9.99655 10.0366 9.9789 10.2806 9.9789 10.5251V15.0932H7.26056C7.26056 15.0932 7.29623 7.6809 7.26056 6.9134H9.97946V8.07174C10.3407 7.5144 10.9869 6.72158 12.4294 6.72158ZM3.03733 6.91399H5.75567V15.0932H3.03733V6.91399Z" fill="#66625F"/>
            </svg>
            </a>
            <a :href="socialLinks.instagram" target="_blank"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99758 5.99872C7.345 5.99872 5.99636 7.34738 5.99636 9C5.99636 10.6526 7.345 12.0013 8.99758 12.0013C10.6502 12.0013 11.9988 10.6526 11.9988 9C11.9988 7.34738 10.6502 5.99872 8.99758 5.99872ZM17.999 9C17.999 7.75716 18.0103 6.52558 17.9405 5.28499C17.8707 3.84402 17.5419 2.56515 16.4883 1.51144C15.4323 0.455477 14.1557 0.129006 12.7148 0.0592092C11.472 -0.0105879 10.2404 0.000669702 8.99983 0.000669702C7.75702 0.000669702 6.52546 -0.0105879 5.28489 0.0592092C3.84395 0.129006 2.5651 0.457728 1.51141 1.51144C0.455469 2.5674 0.129004 3.84402 0.0592081 5.28499C-0.0105877 6.52783 0.000669689 7.75941 0.000669689 9C0.000669689 10.2406 -0.0105877 11.4744 0.0592081 12.715C0.129004 14.156 0.45772 15.4348 1.51141 16.4886C2.56736 17.5445 3.84395 17.871 5.28489 17.9408C6.52771 18.0106 7.75927 17.9993 8.99983 17.9993C10.2426 17.9993 11.4742 18.0106 12.7148 17.9408C14.1557 17.871 15.4346 17.5423 16.4883 16.4886C17.5442 15.4326 17.8707 14.156 17.9405 12.715C18.0125 11.4744 17.999 10.2428 17.999 9ZM8.99758 13.6179C6.44215 13.6179 4.3798 11.5555 4.3798 9C4.3798 6.44452 6.44215 4.38213 8.99758 4.38213C11.553 4.38213 13.6154 6.44452 13.6154 9C13.6154 11.5555 11.553 13.6179 8.99758 13.6179ZM13.8045 5.27148C13.2078 5.27148 12.726 4.78966 12.726 4.193C12.726 3.59635 13.2078 3.11452 13.8045 3.11452C14.4011 3.11452 14.8829 3.59635 14.8829 4.193C14.8831 4.33468 14.8554 4.475 14.8012 4.60593C14.7471 4.73686 14.6677 4.85582 14.5675 4.956C14.4673 5.05618 14.3483 5.13561 14.2174 5.18975C14.0865 5.24388 13.9462 5.27166 13.8045 5.27148Z" fill="#66625F"/>
            </svg>
            </a>
            <a :href="socialLinks.youtube" target="_blank"><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.8843 3.09122C17.8843 1.45064 16.7205 0.130882 15.2825 0.130882C13.3346 0.0364572 11.348 0 9.31779 0H8.6849C6.65965 0 4.66955 0.0364573 2.72165 0.131246C1.28709 0.131246 0.123276 1.45829 0.123276 3.09887C0.0353745 4.39639 -0.00189583 5.69427 0.000213815 6.99215C-0.00330225 8.29003 0.0365465 9.58913 0.11976 10.8894C0.11976 12.53 1.28358 13.8607 2.71813 13.8607C4.76449 13.9592 6.86358 14.0029 8.99783 13.9993C11.1356 14.0065 13.2288 13.9604 15.2775 13.8607C16.7156 13.8607 17.8794 12.53 17.8794 10.8894C17.9638 9.58792 18.0025 8.29004 17.999 6.98851C18.0069 5.69063 17.9687 4.39153 17.8843 3.09122ZM7.27848 10.5686V3.40475L12.3768 6.98486L7.27848 10.5686Z" fill="#66625F"/>
            </svg>

            </a>
            <a href="mailto:concierge@atriumgroup.ch"><svg width="19" height="15" viewBox="0 0 19 15" fill="#A66E5D" xmlns="http://www.w3.org/2000/svg">
            <path class="menu__social-wrapper--info" d="M9.50023 7.5L0.000518919 2.14286L0.000258052 1.00023C0.000131943 0.447854 0.447884 0 1.00026 0H17.9998C18.552 0 18.9996 0.447584 18.9998 0.999785L19 2.14286L9.50023 7.5Z" fill="#A66E5D"/>
            <path class="menu__social-wrapper--info" d="M9.49978 9.10714L18.9996 3.75L18.9996 4.82143L18.9995 13.9286C18.9995 14.3786 18.4021 15 17.944 15H1.05553C0.59743 15 0 14.3786 0 13.9286L1.20796e-05 4.82143L1.93777e-05 3.75C0.585838 4.05964 9.49978 9.10714 9.49978 9.10714Z" fill="#A66E5D"/>
            </svg>
            </a>

            <a href="tel:+41433001070"><svg width="19" height="19" viewBox="0 0 19 19" fill="#A66E5D" xmlns="http://www.w3.org/2000/svg">
            <path class="menu__social-wrapper--info" d="M17 8.999H19C19 3.869 15.127 0 9.98999 0V2C14.052 2 17 4.943 17 8.999Z" fill="#A66E5D"/>
            <path class="menu__social-wrapper--info" d="M10 5.99999C12.103 5.99999 13 6.89699 13 8.99999H15C15 5.77499 13.225 3.99999 10 3.99999V5.99999ZM13.422 11.443C13.2299 11.2683 12.9774 11.1752 12.7178 11.1832C12.4583 11.1912 12.212 11.2998 12.031 11.486L9.63804 13.947C9.06204 13.837 7.90404 13.476 6.71204 12.287C5.52004 11.094 5.15904 9.93299 5.05204 9.361L7.51104 6.96699C7.69745 6.78612 7.80617 6.53982 7.8142 6.2802C7.82223 6.02059 7.72892 5.76804 7.55404 5.57599L3.85904 1.51299C3.68408 1.32035 3.44092 1.2035 3.18119 1.18725C2.92146 1.17101 2.66564 1.25665 2.46804 1.42599L0.298038 3.28699C0.125149 3.46051 0.0219574 3.69145 0.00803782 3.93599C-0.00696218 4.18599 -0.292962 10.108 4.29904 14.702C8.30504 18.707 13.323 19 14.705 19C14.907 19 15.031 18.994 15.064 18.992C15.3086 18.9783 15.5394 18.8747 15.712 18.701L17.572 16.53C17.7415 16.3325 17.8273 16.0768 17.8113 15.817C17.7952 15.5573 17.6785 15.3141 17.486 15.139L13.422 11.443Z" fill="#A66E5D"/>
            </svg>
            </a>
        </div>

        <div class="menu__sidebar-border">

        </div>
        <a class="menu__back-btn" :class="{'no-hover active': active}" @click="goBack" v-if="$route.path != '/'">Back</a>
      </div>
      <div class="menu__booking-enquiry" :class="{'no-hover active': active}">
        <button type="button" name="button" @click="navigateTo('/booking-enquiry')">BOOKING ENQUIRY</button>
      </div>
        <div class="menu__logo" @click="navigateTo('/')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 620" class="logo-svg">
                <defs>
                    <svg:style>.a{fill:transparent;stroke: #d1bea6; stroke-width:5px; }.b{fill:#a66e5d;}.c{fill:#ffffff;}</svg:style>
                </defs>
                <path class="a" d="M540.16,250.36a60.91,60.91,0,1,1,60.9-60.91,61,61,0,0,1-60.9,60.91M643.6,189.45a103.58,103.58,0,1,0-42.26,83.34l.06,18h42.2V189.46h0"/>

                <g class="logotype">
                    <path class="c" d="M117.94,371.15l-12.88,35.14h26.21Zm-10.51,67.91H83.7v-1.47c4.3-.34,7.46-1.13,9.27-6l23.73-63.84-3.28-8.59h17.52l27.9,74c1.25,3.28,4,4.18,7.69,4.41v1.47H131.27v-1.47c6.44-.23,9.27-1.47,9.27-5a16.77,16.77,0,0,0-1-4.41l-7.45-19.66h-27.8L97.15,428.1a13.14,13.14,0,0,0-.79,4.18c0,3.62,3.73,5.08,11.07,5.31Z"/>
                    <path class="c" d="M188.11,380.3h15v4h-15v37.17c0,7.23,3.16,10.4,8.93,10.4a14.91,14.91,0,0,0,8.47-2.6l.34.45c-3.39,5.31-9.49,10.51-17.52,10.51-9.37,0-15.93-5.31-15.93-17.51V384.26h-7v-1.13c7.57-3.5,17.06-11,22-18.31h.68Z"/>
                    <path class="c" d="M250.26,378a8.55,8.55,0,0,1,4.86,1.47v13H253c-7.12,0-13.56.56-18.31,5.31v34.8c0,4.07,2.72,4.52,9.72,5.08v1.36H211.61V437.7c5.31-.34,7.35-.79,7.35-4.86V389.68l-7-5v-.45L234.66,378h.46l-.46,18.65c3.85-11.42,8.25-18.65,15.6-18.65"/>
                    <path class="c" d="M268.22,362.34a9.16,9.16,0,0,1,18.31,0,9.16,9.16,0,0,1-18.31,0m17.18,70.5c0,4.07,2,4.52,7.23,4.86v1.36H262.35V437.7c5.31-.34,7.34-.79,7.34-4.86V389.68l-7-5v-.45L285,378h.45Z"/><path class="c" d="M357.38,429.34c0,4.07.57,5.31,7.46,5.65v1.13l-23,4.07h-.45l.33-11.07c-4.29,5.42-11.29,11.07-19.66,11.07-9.6,0-16.27-5.76-16.27-18.3V390.13c0-4.06-.9-5.53-7-6.66v-.91l22.82-4.4H322l-.56,13.44v28.93c0,7.23,2.82,11.07,9.6,11.07a16.91,16.91,0,0,0,10.62-3.5v-38c0-4.06-.79-5.53-6.89-6.66v-.91l22.72-4.4H358l-.57,13.22Z"/>
                    <path class="c" d="M473.32,437.7v1.36H443.37V437.7c5.2-.34,7-.79,7-4.85V397.48c0-7-2.49-10.51-8.59-10.51a19.66,19.66,0,0,0-11.19,3.84v42c0,4.06,1.81,4.51,7,4.85v1.36H407.89V437.7c5.2-.34,7-.79,7-4.85V397.48c0-7-2.49-10.51-8.59-10.51a19.66,19.66,0,0,0-11.19,3.84v42c0,4.06,1.81,4.51,7,4.85v1.36H372.07V437.7c5.32-.34,7.35-.79,7.35-4.85V389.68l-7-5v-.45L395.12,378h.46l-.46,11.87c3.85-5.2,11.19-11.87,20-11.87,8.24,0,13.67,4.3,15.36,12,3.84-5.31,11.3-12,20.11-12,10.29,0,15.49,6.67,15.49,18.42v36.39c0,4.06,2,4.51,7.23,4.85"/>
                    <path class="c" d="M513,361.55l-8.25,38.64H511c14.69,0,24.74-7.8,24.74-22.37,0-9-4.52-16.27-15.48-16.27Zm-14.69,68.36c-1.47,6.89.79,7.12,8.25,8l-.22,1.13H475.81l.22-1.13c8.59-1,10.29-1.58,11.64-8l13.11-61c1.47-6.89-.79-7.12-8.25-8l.23-1.13H523c18,0,24.07,8.92,24.07,17.85,0,13.79-13.11,20.57-24.52,23.28l10.5,20.22c6.45,12.55,11.08,16.61,17.29,16.84l-.22,1.13h-18c-2.94-2.94-6.33-8.47-9.94-15.82l-10.28-21h-7.69Z"/>
                    <path class="c" d="M562.64,410.81c17.29-3.95,26.1-10.85,26.1-22.6,0-3.84-1.47-7.23-5.87-7.23-9.38,0-18.08,15.14-20.23,29.83M570,440.19c-11.3,0-17.74-8.25-17.74-20.34,0-18.64,15.59-40.68,32.54-40.68,8.93,0,12.88,5,12.88,11,0,13.44-18.42,18.86-35.25,21.91a46.68,46.68,0,0,0-.34,5.65c0,10.62,4.4,16.61,12,16.61,6.66,0,12.42-4.06,17.51-11.3l.79.46c-5.2,9.83-13.22,16.72-22.37,16.72"/>
                    <path class="c" d="M630.58,427.08c0-10.84-20.56-17.74-20.56-30.39,0-7.57,8.36-17.52,19.77-17.52,7.8,0,12.77,4.07,12.77,9.61,0,3.16-3,5.53-5.65,6.55h-.22c-.91-7.23-6-13.11-12.43-13.11a7.78,7.78,0,0,0-8,8c0,9.83,20.79,18.08,20.79,30.05,0,9.72-11.07,19.89-22,19.89-8.93,0-14.69-5.88-14.69-11.86a8.94,8.94,0,0,1,5.65-7.8h.23c1.24,8.7,7.46,16.16,14.57,16.16a9.65,9.65,0,0,0,9.83-9.61"/>
                    <path class="c" d="M665.47,363.47a7.25,7.25,0,0,1,7.12-7.23,6.48,6.48,0,0,1,6.55,6.55A7.26,7.26,0,0,1,672,370a6.48,6.48,0,0,1-6.55-6.55m-4.41,25.42-7.68-4.41V384l19.54-4.86h.46l-12,47.46a23.42,23.42,0,0,0-.9,4.86c0,1.36.45,2,1.69,2,2.37,0,7-3.95,11.3-10l.68.45c-5.31,9.49-12,16.27-18.31,16.27-3.5,0-5.53-2.26-5.53-6a30,30,0,0,1,1.13-7.12Z"/>
                    <path class="c" d="M726,389.91c-.22-4.52-1.8-9-7.34-9-13.56,0-26.55,21.58-26.55,41.47,0,7.57,2,11,6.21,11,9.38,0,23.17-17.4,27.68-43.39m-4.63,50.28c-3.5,0-5.53-2.26-5.53-6a30.36,30.36,0,0,1,1.12-7.12l3.85-15.59c-7.12,16.16-18.19,28.7-26.78,28.7-7,0-11.75-6.33-11.75-16.84,0-22.82,19.09-44.18,36.38-44.18a15.24,15.24,0,0,1,9.38,3.17l4.37-17.91-8.7-4.07v-.45L744,355.05h.46l-17.59,71.58a24.46,24.46,0,0,0-.91,4.86c0,1.36.46,2,1.7,2,2.37,0,7-3.95,11.3-10l.68.45c-5.31,9.49-12,16.27-18.31,16.27"/>
                    <path class="c" d="M759,410.81c17.29-3.95,26.1-10.85,26.1-22.6,0-3.84-1.47-7.23-5.87-7.23-9.38,0-18.08,15.14-20.23,29.83m7.34,29.38c-11.29,0-17.74-8.25-17.74-20.34,0-18.64,15.6-40.68,32.55-40.68,8.92,0,12.88,5,12.88,11,0,13.44-18.42,18.86-35.26,21.91a48.8,48.8,0,0,0-.34,5.65c0,10.62,4.41,16.61,12,16.61,6.67,0,12.43-4.06,17.52-11.3l.79.46c-5.2,9.83-13.22,16.72-22.38,16.72"/>
                    <path class="c" d="M814.74,412.51c5.64-13.56,19.43-33.34,31.18-33.34,5.54,0,7.23,3.05,7.23,8a25,25,0,0,1-.68,5l-9.15,34.46a24.25,24.25,0,0,0-.9,4.86c0,1.36.45,2,1.69,2,2.38,0,7-3.95,11.3-10l.68.45c-5.31,9.49-12,16.27-18.31,16.27-3.5,0-5.53-2.26-5.53-6a30,30,0,0,1,1.13-7.12l9.38-35.36a18.62,18.62,0,0,0,.56-3.73c0-1.58-.56-2.38-2-2.38-7,0-23.84,16.39-28.93,37.07l-4.07,16.38h-9.83L811,388.89l-7.69-4.41V384l19.55-4.86h.45Z"/>
                    <path class="c" d="M871.77,417.7c0,10.62,4,16.61,11.41,16.61,6.55,0,12.09-4.52,17.18-11.75l.67.46c-4.74,9.83-12.65,17.17-21.69,17.17-10.62,0-17.4-8.25-17.4-20.34,0-19.21,15.82-40.68,32.88-40.68,7.45,0,11.86,3.39,11.86,8.93,0,4.41-4.07,7.34-7.91,8h-.22c.45-7.34-.23-14.69-6.78-14.69-10.74,0-20,19-20,36.27"/>
                    <path class="c" d="M919,410.81c17.29-3.95,26.1-10.85,26.1-22.6,0-3.84-1.47-7.23-5.87-7.23-9.38,0-18.08,15.14-20.23,29.83m7.34,29.38c-11.29,0-17.74-8.25-17.74-20.34,0-18.64,15.6-40.68,32.55-40.68,8.92,0,12.88,5,12.88,11,0,13.44-18.42,18.86-35.26,21.91a48.8,48.8,0,0,0-.34,5.65c0,10.62,4.41,16.61,12,16.61,6.67,0,12.43-4.06,17.52-11.3l.78.46c-5.19,9.83-13.21,16.72-22.37,16.72"/>
                    <path class="c" d="M984.32,427.08c0-10.84-20.57-17.74-20.57-30.39,0-7.57,8.37-17.52,19.78-17.52,7.8,0,12.77,4.07,12.77,9.61,0,3.16-3,5.53-5.65,6.55h-.23c-.9-7.23-6-13.11-12.43-13.11a7.78,7.78,0,0,0-8,8c0,9.83,20.79,18.08,20.79,30.05,0,9.72-11.07,19.89-22,19.89-8.93,0-14.69-5.88-14.69-11.86a8.92,8.92,0,0,1,5.65-7.8h.22c1.25,8.7,7.46,16.16,14.58,16.16a9.65,9.65,0,0,0,9.83-9.61"/>
                </g>

                <g class="tagline">
                    <path class="b" d="M161.24,524.06l2.36-.3c.3,4.54,3.25,8.26,9.5,8.26,4.75,0,8.08-2.27,8.08-6.38,0-4.4-2.69-5.69-6.76-6.5l-4.53-.86c-4.45-.85-7.36-3.29-7.36-7.78-.05-5,3.8-8.35,10-8.35,5.78,0,10.27,2.61,10.61,9l-2.31.38c-.39-5.21-3.38-7.48-8.39-7.48-4.66,0-7.53,2.44-7.49,6.24,0,3.13,1.85,5.18,5.91,5.95l4.58.86c4.58.9,8.13,2.87,8.13,8.26S179.51,534,173,534c-7.49,0-11.39-4.24-11.73-9.93"/>
                    <path class="b" d="M198.05,502.88l6.68,26.4a7.73,7.73,0,0,1,.25,1.45h.09c0-.21.13-.72.3-1.45l7.32-26.4h2.48l7.31,26.4a6.14,6.14,0,0,1,.3,1.45h.09c0-.21.09-.77.26-1.45l6.67-26.4h2.44l-8,30.38h-2.91l-7.14-26a8.49,8.49,0,0,1-.26-1.32h-.09a8.38,8.38,0,0,1-.25,1.32l-7.15,26h-2.91l-8-30.38Z"/>
                    <rect class="b" x="246.63" y="502.88" width="2.35" height="30.38"/>
                    <path class="b" d="M264.22,524.06l2.36-.3c.3,4.54,3.25,8.26,9.5,8.26,4.75,0,8.08-2.27,8.08-6.38,0-4.4-2.69-5.69-6.76-6.5l-4.53-.86c-4.45-.85-7.36-3.29-7.36-7.78-.05-5,3.8-8.35,10-8.35,5.78,0,10.27,2.61,10.61,9l-2.31.38c-.39-5.21-3.38-7.48-8.39-7.48-4.66,0-7.53,2.44-7.49,6.24,0,3.13,1.85,5.18,5.91,5.95l4.58.86c4.58.9,8.13,2.87,8.13,8.26S282.49,534,276,534c-7.49,0-11.39-4.24-11.73-9.93"/>
                    <path class="b" d="M299.49,524.06l2.35-.3c.3,4.54,3.26,8.26,9.5,8.26,4.75,0,8.09-2.27,8.09-6.38,0-4.4-2.69-5.69-6.76-6.5l-4.54-.86c-4.45-.85-7.36-3.29-7.36-7.78,0-5,3.81-8.35,10-8.35,5.77,0,10.27,2.61,10.61,9l-2.31.38c-.39-5.21-3.38-7.48-8.39-7.48-4.66,0-7.53,2.44-7.49,6.24,0,3.13,1.84,5.18,5.91,5.95l4.58.86c4.58.9,8.13,2.87,8.13,8.26s-4.07,8.6-10.61,8.6c-7.49,0-11.39-4.24-11.73-9.93"/>
                    <polygon class="b" points="357.7 502.88 357.7 533.26 374.94 533.26 374.94 531.25 360.05 531.25 360.05 502.88 357.7 502.88"/>
                    <path class="b" d="M391,522.78c0,5.56,3,9.11,8.6,9.11s8.69-3.55,8.69-9.11v-19.9h2.35v20c0,6.67-3.93,11.08-11,11.08s-10.95-4.41-10.95-11.08v-20H391Z"/>
                    <path class="b" d="M425,502.88h2.79l7.48,11a11.49,11.49,0,0,1,1.2,2h.09a12.88,12.88,0,0,1,1.2-2l7.44-11h2.65l-10,14.68,10.92,15.7H446L438,521.62a19.84,19.84,0,0,1-1.63-2.57h-.09a28,28,0,0,1-1.62,2.57l-7.92,11.64h-2.69L435,517.34Z"/>
                    <path class="b" d="M464.53,522.78c0,5.56,3,9.11,8.6,9.11s8.69-3.55,8.69-9.11v-19.9h2.35v20c0,6.67-3.93,11.08-11,11.08s-10.95-4.41-10.95-11.08v-20h2.35Z"/>
                    <path class="b" d="M511.82,517.81c5.27,0,7.28-2.86,7.28-6.5s-1.84-6.42-7.28-6.42h-7.91v12.92Zm-7.91,2v13.44h-2.35V502.88H512c7.57,0,9.58,4.24,9.58,8.43,0,3.55-1.75,7.31-7.14,8.21l8.08,13.74h-2.69l-7.92-13.44Z"/>
                    <path class="b" d="M533.79,502.88h2.65l8,13.52c.47.86.9,1.63,1.28,2.4h.09a22.89,22.89,0,0,1,1.28-2.4l8-13.52h2.61l-10.7,18.06v12.32h-2.39V520.94Z"/>
                    <polygon class="b" points="591.31 502.88 591.31 533.26 609.75 533.26 609.75 531.25 593.66 531.25 593.66 518.71 608.73 518.71 608.73 516.66 593.66 516.66 593.66 504.89 609.45 504.89 609.45 502.88 591.31 502.88"/>
                    <path class="b" d="M622.85,502.88h2.79l7.48,11a11.49,11.49,0,0,1,1.2,2h.09a12.88,12.88,0,0,1,1.2-2l7.44-11h2.65l-10,14.68,10.92,15.7h-2.83l-7.91-11.64a19.84,19.84,0,0,1-1.63-2.57h-.08c-.48.86-1,1.71-1.63,2.57l-7.92,11.64H622l10.91-15.92Z"/>
                    <path class="b" d="M678.15,511.18c0-3.85-2.83-6.29-7.27-6.29H663v13.05h7.84c4.44,0,7.27-2.61,7.27-6.76m2.48,0c0,5.39-3.72,8.77-9.67,8.77H663v13.31h-2.35V502.88H671c5.95,0,9.67,3.21,9.67,8.3"/>
                    <polygon class="b" points="695.02 502.88 695.02 533.26 713.46 533.26 713.46 531.25 697.37 531.25 697.37 518.71 712.43 518.71 712.43 516.66 697.37 516.66 697.37 504.89 713.16 504.89 713.16 502.88 695.02 502.88"/>
                    <path class="b" d="M739.91,517.81c5.27,0,7.28-2.86,7.28-6.5s-1.84-6.42-7.28-6.42H732v12.92Zm-7.91,2v13.44h-2.36V502.88h10.44c7.58,0,9.59,4.24,9.59,8.43,0,3.55-1.75,7.31-7.15,8.21l8.09,13.74h-2.69L740,519.82Z"/>
                    <rect class="b" x="766.41" y="502.88" width="2.35" height="30.38"/>
                    <polygon class="b" points="786.78 502.88 786.78 533.26 805.23 533.26 805.23 531.25 789.14 531.25 789.14 518.71 804.2 518.71 804.2 516.66 789.14 516.66 789.14 504.89 804.93 504.89 804.93 502.88 786.78 502.88"/>
                    <path class="b" d="M824.24,502.88l15.7,24.56c.64,1.11,1.16,2,1.67,3h0c0-1-.08-2-.08-3.38V502.88h2.18v30.38h-2.83l-15.7-24.69a24.34,24.34,0,0,1-1.67-3h-.08c.08,1,.08,2,.08,3.38v24.35h-2.14V502.88Z"/>
                    <path class="b" d="M859.42,518c0-9.37,4.75-15.83,13.48-15.83,7.14,0,11,4.49,11.81,10.74l-2.4.22c-.68-5.4-4-8.91-9.54-8.91-6.89,0-10.91,5.53-10.91,13.74,0,8,3.64,13.95,10.95,13.95,5.18,0,8.6-3.3,9.63-8.6l2.27.34c-1.11,5.74-4.62,10.36-11.94,10.36-9.29,0-13.35-7.19-13.35-16"/>
                    <polygon class="b" points="900.63 502.88 900.63 533.26 919.08 533.26 919.08 531.25 902.99 531.25 902.99 518.71 918.05 518.71 918.05 516.66 902.99 516.66 902.99 504.89 918.78 504.89 918.78 502.88 900.63 502.88"/>
                </g>
            </svg>
        </div>
        <transition
          v-on:enter="menuEnter"
	        v-on:leave="menuLeave"
	        appear
        >
            <div class="menu__fullscreen" v-if="active">
                <nav class="menu__nav">
                  <div class="menu__nav-side">
                    Call us at
                  <a href='tel:+41433001070'>+41 43 300 10 70</a>
                  </div>
                    <ul>
                        <li
                            v-for="(menuItem, menuKey) in mainMenu"
                            :key="menuKey"
                            class="menu__link"
                        >
                            <a
                                :href="'/'+menuLocale+menuItem.url"
                                @click.prevent="navigateTo('/'+menuItem.url)"
                                v-html="menuItem.title"
                            ></a>
                        </li>
                    </ul>
                    <div class="menu__nav-side menu__nav-side--right">
                      E-MAIL US
                      <a href="mailto:concierge@atriumgroup.ch">concierge@atriumgroup.ch</a>
                    </div>
                    <div class="menu__buttons">
                        <button class="menu__btn" @click="navigateTo('/booking-enquiry')">Booking enquiry</button>
                        <button  class="menu__btn custom" @click="navigate('#newsletter')">Sign up for newsletter</button>
                    </div>
                </nav>
            </div>
        </transition>
    </header>
</template>

<script>
import {
	TimelineLite,
	Back,
	Elastic,
	Expo
} from "gsap"

export default {
    data() {
        return {
            showOptions: false,
            active: false,
            menuLang: false,
            activeSubmenu: 0
        }
    },
    filters: {
      removeDashes(text = ''){
          return text.split('-').join(' ')
      }
    },
    methods: {
        langHomeUrl(home_url) {
            return this.publicPath + home_url
        },
        navigateTo(url){
            const publicPath = '/' + this.$store.getters.getPublicPath
            const locale = this.$store.getters.getLocale
            if(url != '#'){
                this.$router.push(url)
                this.active = false
            }
        },
        navigate(url){
            this.active = false
            const el = document.querySelector(url);

            if (el) {
                this.$bamUtils.scroll.scrollIntoView(el);
            }

        },
        menuEnter(el, done) {
            let logotype = document.querySelector(".logotype");
            let tagline = document.querySelector(".tagline");
            const logoText = document.querySelectorAll(".c");
            logoText.forEach(item => {
                item.style.fill = "#FFFFFF";
            })


			const tl = new TimelineMax({
				onComplete: done
			})

            tl.set('.a', {
                fill: 'none',
            })

            tl.set(logotype, {
				opacity: 0,
                y: -20
			})

            tl.set(tagline, {
				opacity: 0,
                y: -20
			})

            tl.set(el, {
				scaleY: 0,
				transformOrigin: "0% top"
			})

            tl.set(el, {
				scaleY: 0,
				transformOrigin: "0% top"
			})

            tl.set('.menu__btn', {
              opacity: 0
    		})

			tl.to(el, .4, {
				scaleY: 1,
				transformOrigin: "100% top",
				ease: Power2.easeOut
			})

          tl.to('.a', .4,{
            fill:'#d1bea6',
            ease: Power2.easeOut
          })

            tl.to(logotype, .6, {
                opacity: 1,
                y: 0,
                ease: Power2.easeOut
            })

            tl.to(tagline, .6, {
                opacity: 1,
                y: 0,
                ease: Power2.easeOut
            }, "-=.3")


            tl.staggerFrom(".menu__link", .6, {
                opacity: 0
            }, .15, 1.2)

            tl.staggerFrom(".menu__btn", .6, {
                opacity: 0,
            }, .5, 1.2)
            tl.staggerFrom(".menu__nav-side", .6, {
                opacity: 0,
            }, .5, 1.2)


		},
		menuLeave(el, done) {

			const tl3 = new TimelineMax({
				onComplete: done
			})

          tl3.to('.a', .4,{
            fill:'none'
          })
          tl3.to(".menu__nav-side", .4, {
            opacity: 0
          }, 0.15)

			tl3.to(".menu__link", .4, {
				opacity: 0
			}, 0.15)

			tl3.to(".tagline", .4, {
				opacity: 0
			}, 0.15)

			tl3.to(".logotype", .4, {
				opacity: 0
			}, 0.15)

			tl3.to(".menu__btn", .4, {
				opacity: 0
			}, 0.15)

      tl3.to(el, .4, {
				scaleY: 0,
				ease: Power2.easeOut,
				onComplete: done
			})
        },
        goBack() {

            this.$router.go(-1)
        }
    },
    computed: {
        languages(){
            return this.$store.getters.getLanguages
        },
        options(){
            return this.$store.getters.getOptions
        },
		socialLinks(){
			return this.$store.getters.getSocials
		},
        locale(){
            return this.$store.getters.getLocale
        },
        menuLocale(){
            return (this.locale != this.$store.getters.getOptions.default_language) ? this.locale+'/' : ''
        },
        publicPath(){
            return this.$store.getters.getPublicPath
        },
        mainMenu(){
            return this.$store.getters.getMainMenu
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.scss';
@import '@/styles/framework/mixins.scss';

.menu{
    position: fixed;
    width: 100%;
    z-index: 902;
    &__button{
        background-color: $black_brown;
        outline: none;
        border: 0;
        height: 57px;
        width: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        position: absolute;
        display: flex;
        z-index: 902;
        transform: translateX(64px);
        transition: transform .6s;
        &:hover{
          transform: translateX(0);
          span{ opacity: 1;}
        }
       @include breakpoint-max('tablet'){
        width: 100%;
        padding: 20px;
        justify-content: flex-end;
        transform: translateX(0);

        }
        span{
            font-size: 1.4rem;
            text-transform: uppercase;
            color: #FFF;
            opacity: 0;
            letter-spacing: 1px;
            transition: opacity .7s;
            @include breakpoint-max('tablet'){
              display: none;
            }
        }
    }
    &__sidebar{
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: 901;
      height: 100vh;
      width: 64px;
      background-color: #fff;
      transition:  background-color 1s;
      @include breakpoint-max('tablet'){
        display: none;
      }
    }
    &__social-wrapper{
      position: relative;
      right: 0;
      top: 70px;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      a{
        margin: 12px 0;
        svg{
          path{
            fill: #C4C4C4;
            transition: fill .7s;
            &:hover{
              fill: #66625F;
            }
          }
        }
      }
      &--info{
          fill: #A66E5D !important;
      }
    }
    &__booking-enquiry{
      position: fixed;
      right:-52px;
      top: 58%;
      transition: background-color .6s;
      z-index: 990;
      width: 168px;
      height: 64px;
      transform: rotate(90deg);
       @include breakpoint-max('tablet'){
        display: none;
      }
      @include mobileHeight {
         top: 60%;
      }
      button{
        text-transform: uppercase;
        border: none;
        color: #fff;
        width: 100%;
        height: 100%;
        background-color: $gold_sand;
        transition: border .6s ,background-color .6s;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        outline: none;
      }
    }
    &__lang-buttons-current{
      width: 100%;
      background-color: transparent;
      text-transform: uppercase;
      color: #000;
      border: none;
      padding: 10px;
      transition: color .6s, background-color .6s;
    }
    &__lang-buttons-container{
      position:absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gold_sand;
      transition: transform .8s;
      transform: translateX(100%);
      right:0;
      z-index: 999;
      &.show{
        transform: translateX(0);

      }
      a{
        padding: 4px 15px;
        width: 100%;
        background-color: transparent;
        text-transform: uppercase;
        min-width: 38px;
        color: #fff;
        border: none;
        text-decoration: none;
        &:first-child{
            padding: 4px 0 4px 15px;
        }
      }

    }
    &__lang-buttons-wrapper{
        position: relative;
        background-color:#E0E0E0;
        width: 100%;
        top:56px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color .6s;
    }
    &__lines{
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 4px;
        padding-left: 4px;
    }
    &__line{
        width: 25px;
        height: 1px;
        background-color: $gold_sand;
        display: block;
        margin-top: 4px;
        transition: transform .2s;
        &:last-child{
            margin-top: 7px;
        }
    }
    &__active{
        .menu__line{
            &--one{
                transform: rotate(45deg) translateY(5px);
            }
            &--two{
                transform: rotate(-45deg) translateY(-5px);
            }
        }
    }
    &__fullscreen{
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 900;
        top: 0;
        left: 0;
        background-color: $black_brown;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__nav{
        text-align: center;
        position: relative;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        ul{
            list-style: none;
            margin: 200px 0 0;
            @include tabletSize{
              margin: 100px 0 0;
            }
            li{
                margin: 15px 0;
                a{
                    color: #FFF;
                    letter-spacing: 1px;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 1.8rem;
                    transition: color .8s;
                    &:hover {
                        color: $gold_sand;
                    }
                    @include tabletSize{
                      font-size: 1.4rem;
                    }
                }
                @include tabletSize{
                  margin: 10px 0;
                }
            }
        }
        @include tabletSize {
            padding-bottom: 90px;
        }
    }
    &__buttons{

        display: flex;
        justify-content: center;
        width: 100%;

        button{
            color: $gold_sand;
            border: 1px solid $gold_sand;
            padding: 12px 38px;
            text-transform: uppercase;
            outline: none;
            background-color: transparent;
            min-width: 280px;
            text-align: center;
            margin: 20px;
            font-size: 1.4rem;
            overflow: hidden;
            position: relative;
            transition: color .8s;
            min-height: 43px;
            @include tabletSize{
                margin: 10px 0;
                font-size: 1.2rem;
                padding: 10px 38px;

            }
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $gold_sand;
                z-index: -1;
                transform: scaleX(0);
                transition: transform .8s;
            }
            &:hover {
                color: $text_color;
                &:after {
                    transform: scaleX(1);
                    transform-origin: 50% 50%;
                    transition: transform .8s;
                }
            }

        }
        @include tabletSize{
          flex-direction: column-reverse;
          align-items: center;

        }
    }
    &__logo{
        .logo-svg{
            max-width: 300px;
            position: fixed;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 999;
            .a{
              transition: all .8s;
            }
            @include breakpoint-max('tablet'){
              top: -9px;
              max-width: 200px;

              .a{
                fill: transparent;
                stroke: #d1bea6;
                stroke-width: 5px;
                transition: all .8s;
              }
            }
            &:hover{
              .a{
                fill: #d1bea6 !important;
              }
            }
        }
    }
    &__nav-side{
      color: #FFF;
      letter-spacing: 1px;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.4rem;
      transition: color .8s;
      display: flex;
      flex-direction: column;
      position: absolute;
      left:-20%;
      right: auto;
      top: 50%;
      transform: translate(-30%, -50%);
      @media (max-width:1023px){
        display: none;
      }
      &--right{
        left: auto;
        right: -20%;
        transform: translate(50%,-50%);

      }
      a{
        text-decoration: none;
        color: $gold_sand;
      }
    }
    &__sidebar-border{
      width: 1px;
      position:absolute;
      left: 0;
      top:0;
      bottom: 0;
      height: 100vh;
      background-color: $text_color;
      transition: background-color .6s ,visibility .6s;
      z-index: 1000;
      visibility: hidden;
    }
    &__back-btn {
        position: fixed;
        width: 100px;
        transform: rotate(90deg);
        bottom: 40px;
        right: -18px;
        text-transform: uppercase;
        font-size: $mobile_txt;
        text-decoration: none;
        text-align: center;
        color: $brown;
        @include mobileHeight {
            bottom: 50px;
        }
    }
}

.logotype{
    opacity: 0;
}

.tagline{
    opacity: 0;
}

.menu__button{
  .no-hover  &:hover{
    transform: translateX(64px);
    @include tabletSize{
      transform: translateX(0);
    }
  }
}
.current {
  text-decoration: underline;
}
.menu__button.no-hover{
  &:hover{
    transform: translateX(65px);
    @include tabletSize{
      transform: translateX(0);
    }
  }
  &:hover .menu-button{
    opacity: 0;
  }
}
.menu__sidebar.active{
  background-color:  $black_brown;

  .menu__sidebar-border{
    visibility: visible;

  }
  .menu__lang-buttons-current{
    background-color: $text_color;
    color:  $black_brown;
    right: 0;

  }
  .menu__social-wrapper{
    a{
      svg{
        path{
          fill: #66625F;
        }
      }
    }
  }
}
.menu__button.active {
  transform: translateX(65px);
  @include tabletSize{
    transform: translateX(0);
  }

}
.menu__booking-enquiry.active button{
  background-color: $black_brown;
  color: $text_color;
  border-left: 1px solid   $text_color;
  border-right: 1px solid  $text_color;
  border-bottom: 1px solid  $text_color;
}

.menu__back-btn.active {
    color: $text_color;
}

// to remove
// .custom{
//     opacity: .3 !important;
//     &:after{
//         display: none !important;
//     }
//     &:hover {
//         color: $gold_sand !important;
//     }
// }

</style>
